<template>
  <div>
    <b-modal
      centered
      size="lg"
      ref="my-modal"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <video width="100%" :src="video" controls></video>
      <b-button class="ml-2 mt-4" @click="$emit('close')">{{
        $t("commonLayout.close")
      }}</b-button>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    video: {
      type: String
    }
  },
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.slide = 0;
        this.showModal();
      } else {
        this.hideModal();
      }
    }
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    }
  }
};
</script>
