<template>
  <div class="add-vehicle">
    <confirmation-dialog
      @close="dialogDelete = false"
      :dialog="dialogDelete"
      @confirm="deleteCarParts()"
    />

    <edit-part-dialog
      @close="dialog = false"
      :dialog="dialog"
      :type="partType"
      :partId="selectedId"
      :carProfileId="selectedCarProfileId"
      :partNumber="selectedPartNumber"
      :partName="selectedPartName"
    />

    <print-part-barcode-dialog
        @close="printDialog = false"
        :printDialog="printDialog"
        :printPartId="printPartId"
        :printCarProfileId="printCarProfileId"
    />

    <image-viewer
      @close="dialogImage = false"
      :dialog="dialogImage"
      :images="partsImages"
    />

    <video-viewer
      @close="dialogVideo = false"
      :dialog="dialogVideo"
      :video="partsVideo"
    />

    <div class="page-header">
      <h1>Information & Parts List</h1>
<!--      <div class="add-btn">
        <router-link class="btn custom-btn" class-active="" to="/car-list"
          >{{ $t("commonLayout.backToListing") }}</router-link
        >
      </div>-->
    </div>
    <div class="information-sec">
      <div class="row">
        <div class="col-md-12">
          <b-list-group>
            <b-row>
              <b-col>
                <b-list-group-item>
                  <label>Vin</label>
                  <div class="label-value">
                    {{ carDetailData.vin ? carDetailData.vin.toUpperCase() : "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Sequence</label>
                  <div class="label-value">{{ carDetailData.id || "N/A" }}</div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Stock #</label>
                  <div class="label-value">
                    {{ carDetailData.stock_number || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Year</label>
                  <div class="label-value">
                    {{ carDetailData.year || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Make & Model </label>
                  <div class="label-value">
                    {{ carDetailData.make+" "+carDetailData.model || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Vehical Type</label>
                  <div class="label-value">
                    {{ carDetailData.vehicle_type || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Body Type</label>
                  <div class="label-value">
                    {{ carDetailData.body_type || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>NO. Doors</label>
                  <div class="label-value">
                    {{ carDetailData.doors || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Engine Name</label>
                  <div class="label-value">
                    {{ carDetailData.engine_name || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col style="display: none;">
                <b-list-group-item>
                  <label>Engine ID</label>
                  <div class="label-value">
                    {{ carDetailData.engine_id || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col style="display: none;">
                <b-list-group-item>
                  <label>Country of Manufacturing</label>
                  <div class="label-value">
                    {{ carDetailData.country_of_manufacture || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col style="display: none;">
                <b-list-group-item>
                  <label>Transmission ID</label>
                  <div class="label-value">
                    {{ carDetailData.transmission_id || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Color</label>
                  <div class="label-value">
                    {{ carDetailData.color || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Miles</label>
                  <div class="label-value">
                    {{
                      (carDetailData.miles &&
                        carDetailData.miles
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")) ||
                      "N/A"
                    }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Purchase Price</label>
                  <div class="label-value">
                    {{ carDetailData.purchase_price | USCurrencyfilter }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col style="display: none;">
                <b-list-group-item>
                  <label>Drive Type</label>
                  <div class="label-value">
                    {{ carDetailData.drive_type || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col>
                <b-list-group-item>
                  <label>Licence Plate</label>
                  <div class="label-value">
                    {{ carDetailData.license_plate ? carDetailData.license_plate.toUpperCase() : "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>Description</label>
                  <div class="label-value">
                    {{ carDetailData.notes || "N/A" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>ABS</label>
                  <div class="label-value">
                    {{ carDetailData.has_abs ? "Yes" : "No" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>A/C</label>
                  <div class="label-value">
                    {{ carDetailData.has_ac ? "Yes" : "No" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>Keys</label>
                  <div class="label-value">
                    {{ carDetailData.has_keys ? "Yes" : "No" }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>Leather Seats</label>
                  <div class="label-value">
                    {{ carDetailData.has_leather_seats ? "Yes" : "No" }}
                  </div>
                </b-list-group-item>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>Wheels Size</label>
                  <div class="label-value">
                    {{ carDetailData.wheels_size }}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>YES! WHOLE CAR AVAILABLE FOR SALE</label>
                  <div class="label-value">
                    {{ carDetailData.whole_car_available_for_sale ? 'Yes' : 'No'}}
                  </div>
                </b-list-group-item>
              </b-col>
              <b-col class="col-md-4">
                <b-list-group-item>
                  <label>Sales Price</label>
                  <div class="label-value">
                    {{ carDetailData.sales_price | USCurrencyfilter }}
                  </div>
                </b-list-group-item>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="">
                <b-list-group-item>
                  <label>Images</label>
                  <div class="s-cars-images">
                    <div v-for="(image, index) in carDetailData.images">
                      <!-- <div class="image-info">
                        {{ image.tag.replace('_', ' ') }}
                      </div> -->
                      <img
                        :src="image.url100"
                        alt=""
                        @click="showImageList(image.url500)"
                      />
                    </div>
                  </div>
                </b-list-group-item>
              </b-col>
            </b-row>
            <b-row v-if="vehicleVideo && vehicleVideo[0]">
              <b-col class="">
                <b-list-group-item>
                  <label>Video</label>
                  <div class="s-cars-images">
                    <div
                      v-if="
                        vehicleVideo &&
                        vehicleVideo[0] &&
                        vehicleVideo[0].url100
                      "
                    >
                      <div class="image-info">Video</div>
                      <img
                        :src="vehicleVideo[0].url100"
                        alt=""
                        @click="showVideo(vehicleVideo[0].video_compressed)"
                      />
                    </div>
                  </div>
                </b-list-group-item>
              </b-col>
            </b-row>
          </b-list-group>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="page-header">
          <h5 class="">List of parts from the above Vehicle</h5>
          <div class="add-btn" style="display: inherit;">
            <div style="margin-left:5px">
              <b-button class="btn custom-btn tooltips" data-tooltip="Bulk Delete" @click="dialogDelete = true">
                <svg style="fill: rgb(255 0 0 / 70%);" xmlns="http://www.w3.org/2000/svg" width="15" height="15.002"
                  viewBox="0 0 30 30.002">
                  <g transform="translate(0 0)">
                    <path
                      d="M28.877,28.9a3.732,3.732,0,0,1-5.291,0L1.094,6.395A3.744,3.744,0,0,1,6.385,1.1L28.877,23.6a3.748,3.748,0,0,1,0,5.3ZM28.9,6.395,6.419,28.9a3.743,3.743,0,1,1-5.291-5.295L23.612,1.1A3.743,3.743,0,1,1,28.9,6.395Z"
                      transform="translate(0 0)" />
                  </g>
                </svg>
                <span class="ml-2">Delete</span>
              </b-button>
            </div>
            <div style="margin-left:5px">
              <router-link class="btn custom-btn" :to="{ name: 'Print All Barcode', params: { ids: this.$route.params.id, type: 'car_profile_id' } }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16">
                  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/>
                </svg>
                <span class="ml-2">Print all bar codes</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="custom-table">
          <table
            id="example"
            class="display responsive nowrap"
            cellspacing="0"
            width="100%"
          >
            <thead>
              <tr>
                <th class="desktop tablet-l mobile-l mobile-p">
                  <b-form-group class="mb-0">
                    <b-form-checkbox v-model="isCheckAllParts" @change="onCheckAllParts()">Select All</b-form-checkbox>
                  </b-form-group>
                </th>
                <th class="desktop tablet-l mobile-l mobile-p">Date</th>
                <th class="desktop tablet-p tablet-l mobile-l mobile-p">
                  Part Name
                </th>
                <th class="desktop tablet-p tablet-l">
                  Year/ Make/ Model/ Miles
                </th>
                <th class="desktop">Recommended<br/> Sales $</th>
                <th class="desktop">Interchange</th>
                <th class="desktop">Part<br/> Grade</th>
                <th class="none">Inventory<br/> Days</th>
                <th class="none">Warranty</th>
                <th class="none">Description</th>
                <th class="desktop tablet-l tablet-p">Action</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import { PARTLIST } from "@/constants/apiUrls";
import { ITEMS_PER_PAGE } from "@/constants/common";
import { showSuccess, showFailure } from "../eventbus/action.js";
import tokenService from "./../services/token.service.js";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ImageViewer from "../components/ImageViewer";
import VideoViewer from "../components/VideoViewer";
import EditPartDialog from "@/components/EditPartDialog";
import PrintPartBarcodeDialog from "../components/PrintPartBarcodeDialog";
import EventBus from "@/eventbus";
export default {
  components: {
    ConfirmationDialog,
    ImageViewer,
    VideoViewer,
    EditPartDialog,
    PrintPartBarcodeDialog
  },
  data() {
    return {
      items: [
        {
          id: "1",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "2",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "3",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "4",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "5",
          Stock: "0155220",
          Year_Make_Model: "2020",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "1",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "2",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "3",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "4",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "5",
          Stock: "0155220",
          Year_Make_Model: "2020",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "1",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "2",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "3",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "4",
          Stock: "0155220",
          Year_Make_Model: "2014",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
        {
          id: "5",
          Stock: "0155220",
          Year_Make_Model: "2020",
          $Const: "$2500",
          Parts: "320",
          Parts_Value: "$550",
          Recommended_Sales: "$520",
          Part_Grade: "A",
          Warranty: "15 days",
        },
      ],
      dialogDelete: false,
      table: null,
      selectedId: '',
      selectedPartNumber: '',
      selectedCarProfileId: '',
      selectedPartName: '',
      partType: 'PartType',
      selectedDeleteId: '',
      selectedCarId: this.$route.params.id,
      dialogImage: false,
      dialogVideo: false,
      partsImages: [],
      partsVideo: null,
      dialog: false,
      vehicleVideo: [],
      printDialog: false,
      printPartId: '',
      printCarProfileId: '',
      isCheckAllParts: false,
      selectedPartIds: [],
    };
  },
  computed: {
    ...mapGetters({
      carDetailData: "carlist/getCarDetail",
    }),
  },
  mounted() {
    this.getCarData();
    this.addListeners();
    setTimeout(() => {
      $("li.car-wrapper > a").addClass(
        "router-link-exact-active router-link-active"
      );
    }, 10);
    window.scrollTo({ top: 0, behavior: "smooth" });

    EventBus.$on("resetDialog", () => {
      this.dialog = false;
      this.printDialog = false;
      this.selectedId = '';
      this.selectedPartNumber = '';
      this.selectedCarProfileId = '';
      this.selectedPartName = '';
      this.partType = 'PartType';
      this.printPartId = '';
      this.printCarProfileId = '';
    });
    EventBus.$on("getParts", () => {
      this.table.ajax.reload();
    });
  },

  destroyed() {
    $("li.car-wrapper > a").removeClass(
      "router-link-exact-active router-link-active"
    );
  },

  methods: {
    ...mapActions({
      carDetail: "carlist/getCarDetail",
      deleteParts: "partlist/deleteParts",
      deleteSelectedParts: "partlist/deleteSelectedParts",
    }),

    async addListeners() {
      const self = this;
      $("#example").on("click", ".view-icon", function (e) {
        self.dialogImage = true;
        let url = e.currentTarget.dataset.mediaurl.split(",");
        self.partsImages = [...url];
        // let id = $(".view-icon").data("secret");
        // self.$router.push({ name: "Car Detail", params: { id: Number(id) } });
        e.preventDefault();
      });

      $("#example").on("click", ".edit-icon", function (e) {
        let id = e.currentTarget.dataset.secret;
        let part_number = e.currentTarget.dataset.part_number;
        let car_profile_id = e.currentTarget.dataset.car_profile_id;
        let part_name = e.currentTarget.dataset.part_name;
        // self.$router.push({
        //   name: "Update Vehicle Part",
        //   params: { uid: Number(id) },
        // });

        // console.log(id)
        self.selectedId = id;
        self.selectedPartNumber = part_number;
        self.selectedCarProfileId = car_profile_id;
        self.selectedPartName = part_name;
        self.partType = '';
        // console.log(self.selectedId)
        
        self.dialog = true;
        e.preventDefault();
      });

      $("#example").on("click", ".delete-icon", function (e) {
        self.selectedDeleteId = e.currentTarget.dataset.secret;
        self.dialogDelete = true;
        e.preventDefault();
      });

      $("#example").on("click", ".print-icon", function (e) {
        let partId = e.currentTarget.dataset.secret;
        let partNumber = e.currentTarget.dataset.partnumber;
        let car_profile_id = e.currentTarget.dataset.profile;

        self.printPartId = partId;
        self.printCarProfileId = car_profile_id;
        console.log('print..', self.printPartId, self.printCarProfileId);

        self.printDialog = true;
        e.preventDefault();
      })
    },

    async deleteCarParts() {
      try {
        this.$store.commit("setLoader", true);
        this.selectedPartIds = await ($(".select-checkbox input[type=checkbox]:checked").map(function (i, checkbox) { return checkbox.value; })).toArray();
        if (this.selectedDeleteId) {
          await this.deleteParts(this.selectedDeleteId);
        } else if (this.selectedPartIds.length > 0) {
          await this.deleteSelectedParts({ ids: this.selectedPartIds });
        } else {
          this.dialogDelete = false;
          showFailure("Please select atleast one part to delete");
          return;
        }
        this.table.ajax.reload();
        this.selectedPartIds = [];
        this.isCheckAllParts = false;
        this.dialogDelete = false;
        this.selectedDeleteId = null;
        showSuccess("Parts deleted Successfully");
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    onCheckAllParts() {
      if (this.isCheckAllParts) {
        $("th .custom-control-input").addClass("selected");
        $(".select-checkbox input[type=checkbox]").each(function () { this.checked = true; });
      } else {
        $(".select-checkbox input[type=checkbox]").each(function () { this.checked = false; });
        $("th .custom-control-input").removeClass("selected");
      }
    },

    async showImageList(url) {
      this.dialogImage = true;
      const dataArr = [];
      this.carDetailData.images.forEach(image => {
        dataArr.push(image.url500);
      });
      const index = dataArr.findIndex((item) => item === url);
      dataArr.splice(index, 1);
      this.partsImages = [url, ...dataArr];
    },

    async showVideo(url) {
      this.dialogVideo = true;
      this.partsVideo = url;
    },

    async getCarData() {
      let self = this;
      try {
        this.$store.commit("setLoader", true);
        await this.carDetail(this.$route.params.id);
        console.log(this.carDetailData);
        var data = Object.assign({}, this.carDetailData);
        //start find video
        // if (data.medias && data.medias.find((val) => val.tag === "video-thumbnail")) {
        //   var videoThum = data.medias.find((val) => val.tag === "video-thumbnail");
        //   this.vehicleVideo = [videoThum];
        // }
        if (data.medias && data.medias.find((val) => val.tag === "video")) {
          this.vehicleVideo.push(data.medias.find((val) => val.tag === "video"));
        }
        //end find video

        this.table = $("#example").DataTable({
          ajax: {
            url:
              process.env.VUE_APP_API_HOST +
              PARTLIST +
              "?car_profile_id=" +
              this.$route.params.id,
            type: "GET",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
          },
          columns: [
            {
              orderable: false,
              data: function (row) {
                return (`<form-group class="select-checkbox custom-control custom-control-inline custom-checkbox">
                            <input type="checkbox" 
                              value="${row.id}" 
                              name="selectedPartIds[]" 
                              class="custom-control-input"
                              id="checkbox-${row.id}"/>
                            <label for="checkbox-${row.id}" class="custom-control-label"></label>
                          </form-group>`);
              },
            },
            {
              data: function (row) {
                return (
                  self.$options.filters.DateZoneFilter(
                    moment(row.created_at)
                  ) || "N/A"
                );
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.part_name || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return (
                  full.year +
                  "/" +
                  full.make +
                  "/" +
                  full.model +
                  (full.miles ? "/" + (
                    Number(full.miles)
                          .toFixed(0)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                  ) : '')
                );
              },
            },
            
            {
              data: function (row) {
                return (
                  "$ " +
                  Number(row.recommended_sale_price)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                );
              },
              orderable: false,
            },
            {
              orderable: false,
              data: function (full) {
                return full.interchange_code || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.part_grade || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.inventory_days || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.warranty_days || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return full.description
                  ? `<span class="description" title=${full.description}>${full.description}</span>`
                  : "N/A";
              },
            },
            {
              className: "action",
              orderable: false,
              data: function (row) {
                return `<ul class="action-list">
                    <li>
                      <a
                        class="act-icon view-icon tooltips"
                        data-tooltip="Part Images"
                        data-secret = ${row.id}
                        data-mediaurl = ${row.medias.map((val) => val.url500)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="26"
                          viewBox="0 0 30 26"
                        >
                          <path
                            d="M7.918,8,8.51,3.859A1,1,0,0,1,9.5,3h13a1,1,0,0,1,.99.859L24.082,8H30a1,1,0,0,1,1,1V28a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V9A1,1,0,0,1,2,8ZM16,10.5A7.5,7.5,0,1,0,23.5,18,7.5,7.5,0,0,0,16,10.5ZM16,14a4,4,0,1,0,4,4A4,4,0,0,0,16,14Zm8-1h3a1,1,0,0,0,0-2H24a1,1,0,0,0,0,2Z"
                            transform="translate(-1 -3)"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        class="act-icon edit-icon tooltips"
                        data-tooltip="Edit"
                        data-secret = ${row.id}
                        data-part_number = ${row.part_number}
                        data-car_profile_id = ${row.car_profile_id}
                        data-part_name = "${row.part_name}"
                        :to="{ name: 'Edit Part', params: { id: item.id } }"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.258"
                          height="16.256"
                          viewBox="0 0 16.258 16.256"
                        >
                          <g transform="translate(-2.468 -2.525)">
                            <g transform="translate(2.468 2.525)">
                              <path
                                d="M22.116,16.3,13.8,24.6a12.685,12.685,0,0,1,2.019,1.643,12.127,12.127,0,0,1,1.643,2.019l8.316-8.316a10,10,0,0,0-1.608-2.036A10.025,10.025,0,0,0,22.116,16.3Z"
                                transform="translate(-11.861 -13.943)"
                              />
                              <path
                                d="M72.454,4.54,70.863,2.948a1.456,1.456,0,0,0-2.053,0L67.8,3.958A12.686,12.686,0,0,1,69.819,5.6,11.967,11.967,0,0,1,71.462,7.62l1.01-1.01A1.493,1.493,0,0,0,72.454,4.54Z"
                                transform="translate(-56.621 -2.525)"
                              />
                              <path
                                d="M3.654,70.8,2.491,74.684a.536.536,0,0,0,.667.667l3.884-1.163a10.417,10.417,0,0,0-1.506-1.882A9.711,9.711,0,0,0,3.654,70.8Z"
                                transform="translate(-2.468 -59.118)"
                              />
                            </g>
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        class="act-icon delete-icon tooltips"
                        data-secret = ${row.id}
                        data-tooltip="Delete"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30.002"
                          viewBox="0 0 30 30.002"
                        >
                          <g transform="translate(0 0)">
                            <path
                              d="M28.877,28.9a3.732,3.732,0,0,1-5.291,0L1.094,6.395A3.744,3.744,0,0,1,6.385,1.1L28.877,23.6a3.748,3.748,0,0,1,0,5.3ZM28.9,6.395,6.419,28.9a3.743,3.743,0,1,1-5.291-5.295L23.612,1.1A3.743,3.743,0,1,1,28.9,6.395Z"
                              transform="translate(0 0)"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        data-profiletype = ${row.profile_type}
                        data-partnumber = ${row.part_number}
                        data-profile = ${row.car_profile_id}
                        data-secret = ${row.id}
                        class="act-icon print-icon tooltips"
                        data-tooltip="Print"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                          <rect width="30" height="30" fill="none"/><polyline points="64 80 64 40 192 40 192 80" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><rect x="64" y="152" width="128" height="68" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><path d="M64,176H28V96c0-8.8,7.8-16,17.3-16H210.7c9.5,0,17.3,7.2,17.3,16v80H192" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><circle cx="188" cy="116" r="10"/>
                        </svg>
                      </a>
                    </li>
                  </ul>`;
              },
            },
          ],
          pagingType: "simple_numbers",
          pageLength: ITEMS_PER_PAGE,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
